import React from 'react';
import Section from '@components/core/Section';
import Grid, { Row, Column } from '@components/core/Grid';
import SectionHeader from '@components/SectionHeader';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styles from './road-mapping.module.scss';

const RoadMapping = ({
    sectionHeader: { eyebrow, title, description },
    image: {
        childImageSharp: { fluid },
    },
}) => {
    return (
        <Section className={styles.section}>
            <Grid>
                <Row className={styles.row}>
                    <Column md="5" offsetMd="1">
                        <SectionHeader
                            className={styles.header}
                            eyebrow={eyebrow}
                            title={title}
                            sizeTitle="h3"
                            summary={description}
                        />
                    </Column>
                    <Column md="6">
                        <Img fluid={fluid} />
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

RoadMapping.propTypes = {
    sectionHeader: PropTypes.shape({
        eyebrow: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    image: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
        }),
    }).isRequired,
};

export default RoadMapping;
